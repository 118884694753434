<template>
  <v-dialog v-model="showDialog" width="750px" eager>
    <v-card>
      <v-card-title>
        <h3>{{ convertLang(targetStatTypeName) }}</h3>
      </v-card-title>
      <v-card-text>
        <div class="d-block">
          <div class="d-flex w-100 flex-column">
            <div class="d-flex flex-row align-center justify-space-between">
              <v-autocomplete
                v-model="targetStatID"
                :items="items"
                :label="
                  $t('main.overview.select') +
                  ` ${convertLang(targetStatTypeName)}`
                "
                flat
              >
              </v-autocomplete>
              <v-btn
                ripple
                color="#0065FF"
                class="ml-2 white--text"
                @click="createLookupItem()"
              >
                ADD
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import HealthStatsService from "@/services/healthStatistics";
import { BookingAdditionalStatType } from "@/plugins/constant";
import i18n from "@/plugins/i18n";

export default {
  data() {
    return {
      showDialog: false,
      targetStatID: null,
      targetStatType: BookingAdditionalStatType.Allergy,
      targetStatTypeName: Object.keys(BookingAdditionalStatType)[0],
      items: [],
    };
  },
  async created() {},
  methods: {
    async openPopup(statType = BookingAdditionalStatType.Allergy) {
      this.targetStatID = null;
      this.targetStatType = statType;
      this.targetStatTypeName = Object.keys(BookingAdditionalStatType)[
        statType - 1
      ];
      this.showDialog = true;
      await this.renderLookupStatItems();
    },
    async renderLookupStatItems() {
      let result = await HealthStatsService.searchStatLookupItems(
        this.targetStatType
      );
      if (!result || result.error) {
        this.$toast.error(
          "Cannot find health statistics items. Please try again later."
        );
        return;
      }
      this.items = result.map((i) => ({
        ...i,
        value: i.targetStatID,
        text: `${i.code} - ${i.description}`,
      }));
    },
    async createLookupItem() {
      let patientID = this.$route.params.patientId;
      var bodyData = {
        targetStatID: this.targetStatID,
        targetStatType: this.targetStatType,
      };
      var result = await HealthStatsService.createPatientStatItem(
        patientID,
        bodyData
      );
      if (!result || result.error) {
        this.$toast.error(
          "Cannot create health statistics! Please try again later."
        );
        return;
      }
      this.$emit("onCreateStatItemSuccess", result);
      this.$toast.success("Successfully created health statistics item.");
      this.showDialog = false;
    },
    convertLang(text) {
      console.log(text);
      var val = "";
      switch (text) {
        case "Allergy":
          val = i18n.t("main.overview.allergies");
          break;
        case "PersonalBehaviour":
          val = i18n.t("main.overview.personalBehaviours");
          break;
        case "ChronicDisease":
          val = i18n.t("main.overview.chronicDiseases");
          break;
        case "PastHistory":
          val = i18n.t("main.overview.pastHistoryConditions");
      }
      return val;
    },
  },
};
</script>

<style>
.form-diagnosis {
  width: 100%;
  padding: 5px;
}
.white--text {
  float: right;
}
</style>