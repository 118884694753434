<template>
  <v-dialog v-model="showDialog" max-width="900px">
    <v-card class="clinicalpopup-custom">
      <v-container>
        <v-card-title class="title-center">
          {{ $t("main.overview.clinicalNote") }}
        </v-card-title></v-container
      >
      <v-container class="fixed-editor">
        <quillEditor class="quill-editor" v-model="bodyData.description" />
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="#0065FF"
          class="px-4 py-3 white--text pl-10 pr-10"
          @click="SaveClinicalNote()"
        >
          {{ $t("common.Save") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-card-title>{{ $t("main.overview.previousNote") }}</v-card-title>
      <ClinicalNoteTable
        :count="2"
        ref="ClinicalNoteTable"
        @onOpenEditClinicalNote="openUpdate"
        @onDeleteSuccess="handleDeleteSuccess"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import i18n from "@/plugins/i18n";

import ClinicalNoteTable from "@/components/Dashboard/components/ClinicalNoteTable";
import ClinicalNoteService from "@/services/clinical-note";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    ClinicalNoteTable,
    quillEditor,
  },
  data: () => ({
    showDialog: false,
    clinicalNoteID: null,
    bodyData: {
      description: "",
    },
  }),
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    async openCreate() {
      this.showDialog = true;
      this.clinicalNoteID = null;
      this.bodyData.description = "";
      setTimeout(() => {
        this.$refs.ClinicalNoteTable.renderPatientClinicalNote();
      }, 100);
    },
    handleDeleteSuccess(id) {
      this.$emit("onDeleteSuccess", id);
    },
    async openUpdate(id) {
      this.showDialog = true;
      this.clinicalNoteID = id;
      setTimeout(() => {
        this.$refs.ClinicalNoteTable.renderPatientClinicalNote();
      }, 100);

      var result = await ClinicalNoteService.getDetailClinicalNote(id);
      if (!result || result.error) {
        this.showError(
          "Cannot get Clinical Note at the moment! Please try again later."
        );
      }
      if (result.description) {
        this.bodyData.description = result.description;
      }
    },
    async SaveClinicalNote() {
      var bodyData = this.bodyData;
      if (!bodyData.description.length) {
        this.showError("Description cannot be empty !");
        return;
      }
      bodyData.patientID = this.$route.params.patientId;
      var result;
      if (this.clinicalNoteID) {
        result = await ClinicalNoteService.updateClinicalNote(
          this.clinicalNoteID,
          bodyData
        );
      } else {
        result = await ClinicalNoteService.createClinicalNote(bodyData);
      }

      if (!result || result.error) {
        this.showError(
          "Cannot save Clinical Note at the moment! Please try again later."
        );
        return;
      }

      this.showSuccess(i18n.t("message.popUpmedicine"));
      this.$emit("onSaveNoteSuccess", result);
      this.$refs.ClinicalNoteTable.renderPatientClinicalNote();
    },
    // async onChangePagination(value) {
    //   this.tableOpts.page = value;
    // },
  },
};
</script>

<style lang="scss">
.clinicalpopup-custom {
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: 0px 0px 0px 2px #4c9aff, 0px 2px 2px 0px rgb(0 0 0 / 14%),
      0px 1px 5px 0px rgb(0 0 0 / 12%);
    height: 150px;
    max-height: 150px;
  }

  .title-center {
    display: flex;
    justify-content: center;
  }
  .v-card__text {
    height: 220px;
  }
  .fixed-editor {
    height: 250px;
  }
  .quill-editor {
    height: 150px;
  }
}
</style>