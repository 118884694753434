import HttpClient from '../plugins/httpClient'
import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Emr)
const prefix = '/ClinicalNote';


export default {
    searchClinicalNote(patientID, keySearch = "", page = 1, limit = AppConstant.DefaultPaginationLimit) {
        var query = {
            patientID,
            keySearch,
        }
        var header = {
            page,
            limit,
        }
        return httpClient.get(`/ClinicalNote/Search/{patientID}`, query, header)
    },
    getDetailClinicalNote: (id) => httpClient.get(`${prefix}/Get/${id}`),
    createClinicalNote: (bodyReq) => httpClient.post(`${prefix}/Create`, {}, bodyReq),
    updateClinicalNote: async (id, data) => {
        var query = {
            id
        }
        return httpClient.put(`/ClinicalNote/Update/{id}`, query, data);
    },
    deleteClinicalNote: async (id) => {
        var query = {
            id
        }
        return httpClient.put(`/ClinicalNote/Delete/{id}`, query);
    },
}