<template>
  <v-card flat>
    <v-toolbar flat>
      <v-card-title>{{ $t("main.overview.chronicDiseases") }}</v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openPopupStatItem">
        <v-icon color="black">mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="form-enable-scroll-a">
      <v-container fluid class="fixed-container-a">
        <span class="ml-2" v-if="!items.length">{{
          $t("main.overview.noHeathStatistics")
        }}</span>
        <v-chip-group v-else v-for="(item, ind) in items" :key="ind">
          <v-btn icon @click="deleteItem(item)">
            <v-icon color="black">mdi-delete-outline</v-icon>
          </v-btn>
          <v-chip class="ml-2" label> {{ item.code }} </v-chip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-card-text style="max-width: 120px" v-bind="attrs" v-on="on">
                {{ item.description }}
              </v-card-text>
            </template>
            <span v-html="item.description"></span>
          </v-tooltip>
        </v-chip-group>
      </v-container>
    </div>
    <PopupAdditionalStat
      ref="PopupAdditionalStat"
      @onCreateStatItemSuccess="handleCreateStatItemSuccess"
    />
  </v-card>
</template>

<script>
import HealthStatsService from "@/services/healthStatistics";
import PopupAdditionalStat from "@/components/Dashboard/components/PopupAdditionalStat";
import { BookingAdditionalStatType } from "@/plugins/constant";

export default {
  components: {
    PopupAdditionalStat,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.renderItems();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    openPopupStatItem() {
      this.$refs.PopupAdditionalStat.openPopup(
        BookingAdditionalStatType.ChronicDisease
      );
    },
    handleCreateStatItemSuccess(item) {
      this.items.unshift(item);
    },
    async deleteItem(item) {
      var patientID = this.$route.params.patientId;
      var { id, bookingID } = item;
      var bodyData = { id, bookingID, patientID };
      var result = await HealthStatsService.deletePatientStatItem(bodyData);
      if (!result || result.error) {
        this.showError(
          "Cannot delete health statistics items! Please try again later."
        );
        return;
      }
      var ind = this.items.findIndex((i) => i.id == item.id);
      this.items.splice(ind, 1);
      this.$toast.success("Successfully deleted health statistics item.");
    },
    async renderItems() {
      var patientID = this.$route.params.patientId;
      var result = await HealthStatsService.searchChronicDiseaseStats(
        patientID
      );
      if (!result || result.error) {
        this.showError(
          "Cannot get Allergies health statistics items! Please try again later."
        );
      }
      this.items = result;
    },
  },
};
</script>