var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("personalInfo.lastVisit")))]),_c('v-spacer')],1),_c('div',{staticClass:"fixed-container-lv"},[_c('div',{staticClass:"form-enable-scroll"},[_c('div',{staticClass:"form-table"},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"lg":"2","md":"2"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("main.overview.clinicTableNote.date")))])]),_c('v-col',{staticClass:"text-center",attrs:{"lg":"10","md":"10"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("main.overview.clinicTableNote.description")))])])],1),(!_vm.items.length)?_c('v-row',[_c('v-col',{staticClass:"content-center",attrs:{"lg":"12"}},[_vm._v(" "+_vm._s(_vm.$t("main.overview.clinicTableNote.noVisit"))+" ")])],1):_vm._l((_vm.items),function(item,idx){return _c('v-row',{key:idx},[_c('v-col',{staticClass:"content-center",attrs:{"lg":"2","md":"2"}},[_c('h4',{staticClass:"text-center cusor-pointer cyan--text text--darken-3 underline-text",domProps:{"innerHTML":_vm._s(_vm.formatDate(item.admissionTime))},on:{"click":function($event){return _vm.openEpisodeRecordWindow(
                  item.episodeRecordIDs[0],
                  item.admissionTime
                )}}})]),_c('v-col',{staticClass:"text-left",attrs:{"lg":"9","md":"9"}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("main.overview.chiefComplain"))+" : ")]),_c('v-tooltip',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-subtitle',_vm._g(_vm._b({staticClass:"text-mb-0",staticStyle:{"max-width":"100%"},domProps:{"innerHTML":_vm._s(item.chiefComplaintsStr)}},'v-list-item-subtitle',attrs,false),on))]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.chiefComplaintsStr)}})])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("main.overview.diagnosis"))+" : ")]),_c('v-tooltip',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-subtitle',_vm._g(_vm._b({staticClass:"text-mb-0",staticStyle:{"max-width":"100%"},domProps:{"innerHTML":_vm._s(item.diagnosisStr)}},'v-list-item-subtitle',attrs,false),on))]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.diagnosisStr)}})])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("main.overview.service"))+" : ")]),_c('div',{staticClass:"last-visit-list-orders"},[_c('v-chip-group',{attrs:{"show-arrows":""}},_vm._l((item.serviceItems),function(service){return _c('v-chip',{key:service.serviceID + service.typeID},[_vm._v(" "+_vm._s(service.description)+" ")])}),1)],1)],1)],1),_c('v-col',{staticClass:"d-flex w-100 align-center justify-center h-100 w-100",attrs:{"lg":"1","md":"1"}},[_c('GeneralPrint',{attrs:{"childrensMenu":_vm.childrensMenu,"optionPrint":_vm.OptionPrint.Hover,"patientInfo":item.patientInfo,"listWantToPrint":_vm.listWantToPrint,"justIcon":_vm.justIcon}})],1)],1)})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }