<template>
  <v-card flat>
    <v-toolbar flat>
      <v-card-title>{{ $t("personalInfo.lastVisit") }}</v-card-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div class="fixed-container-lv">
      <div class="form-enable-scroll">
        <div class="form-table">
          <v-row>
            <v-col lg="2" md="2" class="text-center">
              <h4>{{ $t("main.overview.clinicTableNote.date") }}</h4>
            </v-col>
            <v-col lg="10" md="10" class="text-center">
              <h4>{{ $t("main.overview.clinicTableNote.description") }}</h4>
            </v-col>
          </v-row>
          <v-row v-if="!items.length">
            <v-col lg="12" class="content-center">
              {{ $t("main.overview.clinicTableNote.noVisit") }}
            </v-col>
          </v-row>
          <v-row v-else v-for="(item, idx) in items" :key="idx">
            <v-col lg="2" md="2" class="content-center">
              <h4
                @click="
                  openEpisodeRecordWindow(
                    item.episodeRecordIDs[0],
                    item.admissionTime
                  )
                "
                class="text-center cusor-pointer cyan--text text--darken-3 underline-text"
                v-html="formatDate(item.admissionTime)"
              ></h4>
            </v-col>
            <v-col lg="9" md="9" class="text-left">
              <!-- chief complain -->
              <v-list-item>
                <v-list-item-title>
                  {{ $t("main.overview.chiefComplain") }} :
                </v-list-item-title>
                <v-tooltip bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-subtitle
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 100%"
                      class="text-mb-0"
                      v-html="item.chiefComplaintsStr"
                    >
                    </v-list-item-subtitle>
                  </template>
                  <span v-html="item.chiefComplaintsStr"></span>
                </v-tooltip>
              </v-list-item>
              <!-- diagnosis -->
              <v-list-item>
                <v-list-item-title>
                  {{ $t("main.overview.diagnosis") }} :
                </v-list-item-title>
                <v-tooltip bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-subtitle
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 100%"
                      class="text-mb-0"
                      v-html="item.diagnosisStr"
                    >
                    </v-list-item-subtitle>
                  </template>
                  <span v-html="item.diagnosisStr"></span>
                </v-tooltip>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  {{ $t("main.overview.service") }} :
                </v-list-item-title>
                <div class="last-visit-list-orders">
                  <v-chip-group show-arrows>
                    <v-chip
                      v-for="service in item.serviceItems"
                      :key="service.serviceID + service.typeID"
                    >
                      {{ service.description }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </v-list-item>
            </v-col>
            <v-col
              lg="1"
              md="1"
              class="d-flex w-100 align-center justify-center h-100 w-100"
            >
              <!-- <v-menu left transition="scale-transition" close-on-click>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on">
                    <v-icon dark title="Print discharge summary">
                      mdi-printer
                    </v-icon>
                  </v-btn>
                </template>
                <v-list link>
                  <v-list-item
                    v-for="(itemMenuPrint, menuIndex) in item.menuPrinting"
                    :key="menuIndex"
                    style="cursor: pointer"
                  >
                    <v-list-item-title
                      v-if="itemMenuPrint.childrens.length == 0"
                      @click="printLink(itemMenuPrint.link, itemMenuPrint.text)"
                    >
                      {{ itemMenuPrint.text }}
                    </v-list-item-title>

                    <template v-else>
                      <v-menu
                        open-on-hover
                        :close-on-click="true"
                        left
                        content-class="menu-childrens"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item-title v-bind="attrs" v-on="on">
                            {{ itemMenuPrint.text }}
                          </v-list-item-title>
                        </template>
                        <v-list-item
                          v-for="(
                            itemChild, indexChild
                          ) in itemMenuPrint.childrens"
                          :key="indexChild"
                          link
                        >
                          <v-list-item-title
                            v-text="itemChild.text"
                            @click="printLink(itemChild.link, itemChild.text)"
                            style="cursor: pointer"
                          ></v-list-item-title>
                        </v-list-item>
                      </v-menu>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu> -->
              <GeneralPrint
                :childrensMenu="childrensMenu"
                :optionPrint="OptionPrint.Hover"
                :patientInfo="item.patientInfo"
                :listWantToPrint="listWantToPrint"
                :justIcon="justIcon"
              >
              </GeneralPrint>
              <!-- @click="printDischargeSummary(item.episodeRecordIDs[0])" -->
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
import PatientService from "@/services/patient";
import GeneralPrint from "@/components/prescription/GeneralPrint";
import { OptionPrint } from "@/plugins/constant";

export default {
  components: {
    GeneralPrint,
  },
  data() {
    return {
      OptionPrint,
      items: [],
      childrensMenu: true,
      listWantToPrint: [],
      justIcon: false,
    };
  },
  created() {
    this.renderPatientLastVisits();
  },
  async mounted() {
    this.sockets.common.on(
      "EpisodeRecordCanceled",
      this.renderPatientLastVisits
    );
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    formatDate(date) {
      if (!date) return "";
      return `${moment(date).format("DD-MM-YYYY")} <br/> <h5>${moment(
        date
      ).format("HH:mm")}</h5>`;
    },
    async openEpisodeRecordWindow(episodeRecordID, admissionTime) {
      if (!episodeRecordID) return;
      var patientID = this.$route.params.patientId;
      var key = `patient/${patientID}/episodeRecord/${episodeRecordID}/chief-complaints`;
      var label = moment(admissionTime).format("DD/MM/YYYY");
      var tabData = {
        key,
        label,
      };
      parent.postMessage(
        { event: "ADD_EPISODE_RECORD_TAB", data: tabData },
        "*"
      );
    },
    async renderPatientLastVisits() {
      var patientID = this.$route.params.patientId;
      var result = await PatientService.searchLastVisits(
        patientID,
        "",
        1,
        1000
      );
      if (!result || result.error) {
        this.showError(
          "Cannot get patient last visits! Please try again later."
        );
      }
      this.items = result.items.map((i) => ({
        ...i,
        chiefComplaintsStr: i.chiefComplaints.join(", ") || "None",
        diagnosisStr:
          i.diagnosisResults
            .map((i) => `${i.icDxCode} - ${i.icDxDescription}`)
            .join(", ") || "None",
        // menuPrinting: this.parsePaAndEpToLink(i.episodeRecordIDs[0]),
        patientInfo: {
          episodeRecordID: i.episodeRecordIDs[0],
          patientID: this.$route.params.patientId,
        },
        serviceItems: i.serviceItems.filter(
          (s) => s.typeID == 3 || s.typeID == 2
        ),
      }));
      console.log(this.items);
    },

    // parsePaAndEpToLink(episodeRecordID) {
    //   var menuPrinting = [
    //     {
    //       text: "Discharge summary",
    //       link:
    //         "/patient/${patientID}/prescription/${episodeRecordID}/discharge",
    //       icon: "mdi-file-document",
    //       childrens: [],
    //     },
    //     {
    //       text: "Medicine prescription",
    //       link:
    //         "/patient/${patientID}/prescription/${episodeRecordID}/medicine",
    //       icon: "mdi-medical-bag",
    //       childrens: [],
    //     },
    //     {
    //       text: "Glass prescription",
    //       icon: "mdi-file-document-multiple",
    //       childrens: [
    //         {
    //           text: "Rimmed Glass ",
    //           link:
    //             "/patient/${patientID}/prescription/${episodeRecordID}/glass",
    //         },
    //         {
    //           text: "Soft Cls Glass",
    //           link:
    //             "/patient/${patientID}/prescription/${episodeRecordID}/cls/soft",
    //         },
    //         {
    //           text: "Hard Cls Glass",
    //           link:
    //             "/patient/${patientID}/prescription/${episodeRecordID}/cls/hard",
    //         },
    //         {
    //           text: "OrthoK Cls Glass",
    //           link:
    //             "/patient/${patientID}/prescription/${episodeRecordID}/cls/orthok",
    //         },
    //       ],
    //     },
    //   ];
    //   let patientID = this.$route.params.patientId;

    //   var menu = menuPrinting.map((i) => {
    //     if (i.childrens.length == 0) {
    //       i.link = i.link.replace("${patientID}", patientID);
    //       i.link = i.link.replace("${episodeRecordID}", episodeRecordID);
    //     } else {
    //       let listPathChildrens = i.childrens.map((iChild) => {
    //         iChild.link = iChild.link.replace("${patientID}", patientID);
    //         iChild.link = iChild.link.replace(
    //           "${episodeRecordID}",
    //           episodeRecordID
    //         );
    //         return iChild;
    //       });
    //       i.childrens = listPathChildrens;
    //     }
    //     return i;
    //   });
    //   return JSON.parse(JSON.stringify(menu));
    // },

    // printLink(link, title) {
    //   // close menu
    //   this.menuParent = false;
    //   this.menuChildren = false;
    //   // open link with new tab
    //   window.open(link, title, "width=800, height=500");
    // },
  },
};
</script>

<style lang="scss" scoped>
.menu-childrens {
  background-color: #ffffff;
  z-index: 9999;
}
.cusor-pointer {
  cursor: pointer;
}
.text-mb-0 p {
  margin-bottom: 0px;
}
.last-visit-list-orders {
  float: right;
  width: calc(100% - 120px);
  margin-left: -10px;
  display: inline-block;
}
.fixed-container-lv {
  padding: 0 10px;
  max-height: calc(100vh - 123px);
  overflow-x: hidden;
  overflow-y: scroll;

  .v-list-item {
    min-height: 38px;
  }
  .v-list-item__title {
    max-width: 130px;
    margin: 0px 40px 0px 0px;
  }
  .form-enable-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 87.1vh;
  }
  .v-card__title {
    line-height: 1rem;
  }
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.underline-text {
  text-decoration: underline;
}
</style>