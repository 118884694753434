<template >
  <v-container fluid class="greyBg pt-5 pb-8 overview-container">
    <v-row class="pa-0 ma-0">
      <v-col cols="12" lg="6" md="12">
        <v-row>
          <v-col cols="12" md="6">
            <Allergies class="fixed-height" />
          </v-col>
          <v-col cols="12" md="6">
            <PersonalBehavior class="fixed-height" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <ChronicDisease class="fixed-height" />
          </v-col>
          <v-col cols="12" md="6">
            <PastHistoryCondition class="fixed-height" />
          </v-col>
          <v-col cols="12" md="12">
            <ClinicalNote class="fixed-height" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <LastVisit class="fixed-col-right" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Allergies from "@/components/Dashboard/components/Allergies";
import PersonalBehavior from "@/components/Dashboard/components/PersonalBehavior";
import ChronicDisease from "@/components/Dashboard/components/ChronicDisease";
import PastHistoryCondition from "@/components/Dashboard/components/PastHistoryCondition";
import ClinicalNote from "@/components/Dashboard/components/ClinicalNote";
import LastVisit from "@/components/Dashboard/components/LastVisit";

export default {
  name: "Overview",
  components: {
    Allergies,
    PersonalBehavior,
    ChronicDisease,
    PastHistoryCondition,
    ClinicalNote,
    LastVisit,
  },
  data: () => ({}),
};
</script>

<style lang="scss">
.full-height {
  height: 100%;
}
.fixed-height {
  height: 30vh;
  min-height: 250px;
  // min-width: 400px;
}
.fixed-height-1-5 {
  height: 46.3vh;
}
.fixed-col-right {
  height: 94.9vh;
}
.form-table {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-right: 0px;
  > .row {
    margin: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    margin-bottom: -1px;
    .col {
      border-right: 1px solid rgba(0, 0, 0, 0.6);
    }
  }
}
.overview-container {
  .v-card__title {
    // padding: 10px;
    padding: 2px;
  }
  .v-card__title.title-container {
    margin: 5px -2px 11px -2px;
    font-size: 18px;
  }
  .fixed-container-a {
    padding: 0 10px;
    .v-card__text {
      width: 100%;
      line-height: 32px;
    }
    .v-chip-group .v-chip {
      margin: 0px 10px 0px 0px;
    }
    .v-card__subtitle,
    .v-card__text,
    .v-card__title {
      padding: 0px 10px;
    }
    .v-slide-group__content {
      display: -webkit-box;
    }
  }
  .form-enable-scroll-a {
    overflow-y: auto;
    overflow-x: hidden;
    height: 22.1vh;
  }
  .v-card__title {
    line-height: 1rem;
  }

  .m-0 {
    margin: 0 !important;
  }
  .title-container {
    margin: 15px 0px;
  }
}
</style>