<template>
  <v-card flat>
    <v-toolbar flat>
      <v-card-title>{{ title }}</v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="handleCreateClinicalNoteClick">
        <v-icon color="black">{{ add }}</v-icon>
      </v-btn>
    </v-toolbar>
    <ClinicalNoteTable
      ref="ClinicalNoteTable"
      @onOpenEditClinicalNote="handleOpenClinicalNotePopup"
    />
    <ClinicalNotePopup
      ref="ClinicalNotePopup"
      @onSaveNoteSuccess="handleSaveNoteSuccess"
      @onDeleteSuccess="handleDeleteSuccess"
    />
  </v-card>
</template>

<script>
import ClinicalNotePopup from "@/components/Dashboard/Form/ClinicalNotePopup";
import ClinicalNoteTable from "@/components/Dashboard/components/ClinicalNoteTable";
import i18n from "@/plugins/i18n";

export default {
  name: "ClinicalNote",
  components: {
    ClinicalNotePopup,
    ClinicalNoteTable,
  },
  data: () => ({
    title: i18n.t("main.overview.clinicalNote"),
    edit: "mdi-pencil",
    expand: "mdi-arrow-expand",
    add: "mdi-plus",
    itemPerPage: 1000,
  }),
  methods: {
    handleCreateClinicalNoteClick() {
      this.$refs.ClinicalNotePopup.openCreate();
    },
    handleSaveNoteSuccess() {
      this.$refs.ClinicalNoteTable.renderPatientClinicalNote();
    },
    handleOpenClinicalNotePopup(id) {
      this.$refs.ClinicalNotePopup.openUpdate(id);
    },
    handleDeleteSuccess() {
      this.$refs.ClinicalNoteTable.renderPatientClinicalNote();
    },
    async handleOpenDialogConfirmPopup(id) {
      this.$refs.DialogConfirm.confirm();
      if (!(await this.$refs.DialogConfirm.confirm())) return;
      this.$refs.ClinicalNoteTable.deleteClincalNote(id);
    },
  },
};
</script>

<style lang="scss">
.fixed-container-cn {
  .v-card__title {
    line-height: 1rem;
  }
  .form-enable-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: 21.1vh;
  }
}
</style>