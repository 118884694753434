<template>
  <v-container class="fixed-container-cn" fluid>
    <div class="form-enable-scroll">
      <div class="form-table">
        <v-row>
          <v-col cols="2" class="text-center">
            <h4>{{ $t("main.overview.clinicTableNote.date") }}</h4>
          </v-col>
          <v-col cols="3" class="text-center">
            <h4>{{ $t("main.overview.clinicTableNote.staffName") }}</h4>
          </v-col>
          <v-col cols="5" class="text-center">
            <h4>{{ $t("main.overview.clinicTableNote.note") }}</h4>
          </v-col>
          <v-col cols="2" class="text-center">
            <h4>{{ $t("main.overview.clinicTableNote.action") }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="!items.length">
          <v-col cols="12" class="content-center">
            {{ $t("main.overview.clinicTableNote.noVisit") }}
          </v-col>
        </v-row>
        <v-row v-else v-for="(item, idx) in items" :key="idx">
          <v-col cols="2" class="text-center">
            <span v-html="formatDate(item.date)"></span>
          </v-col>
          <v-col cols="3" class="text-center">
            <span v-html="item.staffName"></span>
          </v-col>
          <v-col cols="5" class="text-center">
            <span v-html="item.description"></span>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-btn
              @click="handleUpdateClinicalNoteClick(item.id)"
              class="mx-2 elevation-0"
              fab
              small
            >
              <v-icon dark> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              @click="deleteClincalNote(item.id)"
              class="mx-2 elevation-0"
              fab
              small
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <DialogConfirm ref="DialogConfirm" />
  </v-container>
</template>

<script>
import moment from "moment";
import DialogConfirm from "@/components/DialogConfirm";
// import PatientService from "@/services/patient";
import ClinicalNoteService from "@/services/clinical-note";
import i18n from "@/plugins/i18n";

export default {
  name: "LastVisitTable",
  components: {
    DialogConfirm,
  },
  props: {
    count: {
      type: Number,
      default: 10000,
    },
  },
  data: () => ({
    title: "Last Visit",
    edit: "mdi-pencil",
    expand: "mdi-arrow-expand",
    // itemsPerPage: 1000,
    items: [],
  }),
  created() {
    this.renderPatientClinicalNote();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    formatDate(date) {
      if (!date) return "";
      return `${moment(date).format("DD-MM-YYYY")} <br/> <h5>${moment(
        date
      ).format("HH:mm")}</h5>`;
    },
    async renderPatientClinicalNote() {
      var patientID = this.$route.params.patientId;
      var result = await ClinicalNoteService.searchClinicalNote(
        patientID,
        "",
        1,
        this.count
      );
      if (!result || result.error) {
        this.showError(
          "Cannot get patient clinical note! Please try again later."
        );
      }
      this.items = result.items.map((i) => ({
        ...i,
        staffName: i.staffName,
        date: i.dateCreated,
        note: i.description,
      }));
      // this.items = result.items;
    },
    async deleteClincalNote(id) {
      if (!(await this.$refs.DialogConfirm.confirm())) return;
      var result = await ClinicalNoteService.deleteClinicalNote(id);
      if (result.error) {
        this.showError(
          "Cannot delete this clincal note. Please try again later!"
        );
        return;
      }
      var ind = this.items.findIndex((i) => i.id == id);
      this.items.splice(ind, 1);
      this.showSuccess(i18n.t("message.clinicalNoteTable"));
      this.$emit("onDeleteSuccess", this.id);
      this.renderPatientClinicalNote();
    },
    handleUpdateClinicalNoteClick(id) {
      this.$emit("onOpenEditClinicalNote", id);
    },
    async handleDeleteClinicalNoteClick(id) {
      this.$emit("onOpenDeleteClinicalNote", id);
    },
  },
};
</script>

<style scoped lang="scss">
.form-table {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-right: 0px;
  > .row {
    margin: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    margin-bottom: -1px;
    .col {
      border-right: 1px solid rgba(0, 0, 0, 0.6);
    }
  }
}
</style>