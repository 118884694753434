import HttpClient from '@/plugins/httpClient'
import { ApiGateway } from '@/plugins/gateway'
import { BookingAdditionalStatType } from "@/plugins/constant";

var httpClient = new HttpClient(ApiGateway.Pas);

const additionalPrefix = '/HealthStatistics/AdditionalStats';

export default {
  searchStatLookupItems(statType = BookingAdditionalStatType.Allergy) {
    return httpClient.get(`${additionalPrefix}/LookupItems`, { statType });
  },
  createPatientStatItem(patientID, dto) {
    return httpClient.post(`${additionalPrefix}/Create/${patientID}`, null, dto);
  },
  deletePatientStatItem(dto) {
    return httpClient.put(`${additionalPrefix}/Delete`, null, dto);
  },
  searchAllergyStats(patientID, keySearch = "") {
    var query = { patientID, keySearch }
    return httpClient.get(`${additionalPrefix}/Allergy/{patientID}`, query);
  },
  searchPersonalBehaviourStats(patientID, keySearch = "") {
    var query = { patientID, keySearch }
    return httpClient.get(`${additionalPrefix}/PersonalBehaviour/{patientID}`, query);
  },
  searchPastHistoryStats(patientID, keySearch = "") {
    var query = { patientID, keySearch }
    return httpClient.get(`${additionalPrefix}/PastHistory/{patientID}`, query);
  },
  searchFamilyHistoryStats(patientID, keySearch = "") {
    var query = { patientID, keySearch }
    return httpClient.get(`${additionalPrefix}/FamilyHistory/{patientID}`, query);
  },
  searchChronicDiseaseStats(patientID, keySearch = "") {
    var query = { patientID, keySearch }
    return httpClient.get(`${additionalPrefix}/ChronicDisease/{patientID}`, query);
  },
}